import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import useMediaQuery from "@mui/material/useMediaQuery";
import MarketplaceSelect from "../controls/MarketplaceSelect";
import { useTheme } from "@mui/material/styles";
import { useCrawlRequestMutation } from "../../store/api";
import { DialogContentText, DialogTitle } from "@mui/material";
import {
  useGetCrawlerMarketplacesQuery,
  useGetUserBrandsQuery,
  useLazyGetProductsByBrandQuery,
} from "../../store/api";
import useAuth from "../../hooks/useAuth";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ListItemText from "@mui/material/ListItemText";
import DatePicker from "@mui/lab/DatePicker";
import { isEmpty } from "lodash";
import dayjs from "dayjs";

export default function CrawlDialog(props) {
  const { open, handleClose, selected } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const { user } = useAuth();
  const [marketplaces, setMarketplaces] = React.useState([selected.platform]);
  const [brand, setBrand] = React.useState(selected.brand || "");
  const [product, setProduct] = React.useState(selected.product || "");
  const [keywords, setKeywords] = React.useState(
    selected.brand && selected.product ? [brand, product] : []
  );
  const [expires, setExpires] = React.useState(dayjs().add(1, "month"));
  const [limit, setLimit] = React.useState(50);

  const [crawl] = useCrawlRequestMutation();
  const { data: all_marketplaces } = useGetCrawlerMarketplacesQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: brands } = useGetUserBrandsQuery(
    user?.auth ? undefined : skipToken
  );
  const { data: products } = useLazyGetProductsByBrandQuery(
    !isEmpty(brand) ? { brand: brand } : skipToken
  );

  const handleCrawlRequest = () => {
    crawl({
      product: product,
      brand: brand,
      limit: limit,
      keywords: [brand, product],
      marketplaces: [selected.platform],
      expires: expires.toDate(),
    });
    handleClose();
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth={"md"}
      open={open}
      onClose={handleClose}
      aria-labelledby="crawl-dialog-title"
    >
      <DialogContent>
        <DialogTitle>Crawl Request</DialogTitle>
        <DialogContentText mb={5}>
          Select options below for this crawl request. The request will be
          processed in the order it is received.
        </DialogContentText>
        {brands && (
          <FormControl fullWidth sx={{ mb: 5 }}>
            <InputLabel id="brand-select-label">Brand</InputLabel>
            <Select
              fullWidth
              sx={{ height: 50 }}
              labelId="brand-select-label"
              id="brand-select"
              value={brand}
              label="Brand"
              onChange={(e) => {
                setBrand(e.target.value);
                setProduct("");
                setKeywords([]);
              }}
            >
              {brands?.map((b, index) => (
                <MenuItem key={index} value={b.name}>
                  <ListItemText primary={b.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <FormControl fullWidth sx={{ mb: 5 }}>
          <InputLabel id="product-select-label">Product</InputLabel>
          <Select
            sx={{ height: 50 }}
            fullWidth
            labelId="product-select-label"
            id="product-select"
            value={product}
            label="Product"
            onChange={(e) => {
              setProduct(e.target.value);
              setKeywords(
                products.find((p) => p.name === e.target.value).keywords || []
              );
            }}
          >
            {products?.map((b, index) => (
              <MenuItem key={index} value={b.name}>
                <ListItemText primary={b.name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {all_marketplaces && (
          <MarketplaceSelect
            all_marketplaces={all_marketplaces}
            marketplaces={marketplaces}
            setMarketplaces={setMarketplaces}
          />
        )}
        <Autocomplete
          sx={{ mb: 6, mt: 5, height: 50 }}
          multiple
          id="keywords"
          options={products?.find((p) => p.name === product)?.keywords || []}
          value={keywords}
          onChange={(_event, newValue) => {
            setKeywords(newValue);
          }}
          freeSolo
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Keywords"
              placeholder="Search Keywords"
            />
          )}
        />
        <FormControl sx={{ mr: 5 }}>
          <DatePicker
            label="End Date"
            value={expires}
            onChange={(newValue) => setExpires(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </FormControl>
        <FormControl sx={{ width: 150 }}>
          <InputLabel id="limit-label">Limit</InputLabel>
          <Select
            labelId="limit-label"
            id="demo-simple-select"
            value={limit}
            label="limit"
            onChange={(e) => setLimit(e.target.value)}
          >
            <MenuItem value={1}>1</MenuItem>
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} autoFocus>
          Close
        </Button>
        <Button
          onClick={handleCrawlRequest}
          disabled={
            isEmpty(brand) ||
            isEmpty(product) ||
            isEmpty(keywords) ||
            isEmpty(marketplaces)
          }
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
